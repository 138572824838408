import MaverickArrow from '../../../assets/images/home-images/yellow_pipes.png';
import Brochure from "../../../assets/pdfs/Pillar Hydraulic Solutions_Brochure(Compressed).pdf";

function AboutUsHome() {
  return (
    <div className="about-us-home-container">

    <div className="blue bar"></div>
    <div className="grey bar"></div>
    <div className="black bar"></div>

      <div className='about-us-text-container'>
        <h3 className="main-subhead">
          <span className="bold">About</span> Us
        </h3>
        <div className="spacer"></div>
        <h4 className="smaller-head mb20">Pillar Hydraulic Solutions</h4>
        {/* <p className="body-text">
          Pillar Hydraulic's mission is to act as the preferred supply partner of
          piston bladder pressure vessel tools to the world’s most important oil and gas companies. <br /><br />
          With the most advanced quality assurance technologies and processes in the industry, we are driven to position our customers for success as their trusted source of high-quality hydraulics today — and for years to come.
        </p> */}
        <p className='body-text'>
          Pillar Hydraulic Solutions Inc offers the most comprehensive <span className='body-text blue-text'>hydraulic engine</span> starting product line with strong dedication of product quality, exceptional customer service & commitment to quick delivery of services.<br />
        </p>
        <p className='body-text'>
          Pillar is introducing a solution to the Oil & Gas industry that is safe and provides zero emissions while starting industrial size engines helping to meet the stringent environmental regulatory mandates for clean energy.<br />
        </p>
        <p className="body-text">
      Through our strategic partnership with 
      Steelhaus Technologies Inc., Pillar is able 
      to offer the most advanced engineering, 
      design and manufacturing to provide our 
      clients with highest quality of piston 
      accumulators and hydraulic components 
      in the industry!
      </p>
        <p className='body-text'>
          View our brochure for <span className='body-text blue-text'>more info!</span>
        </p>
        <a
          href="https://pillarhydraulics.com/Brochure.pdf"
          target="_blank"
          rel="noreferrer"
        >
            <button className="main-btn">
                Open Brochure
            </button>
        </a>
      </div>
      {/* <div>
          <img className="arrow-img" src={MaverickArrow} alt="Maverick Arrow"/>
      </div> */}
    </div>
  );
}

export default AboutUsHome;
