import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getTubingAnchorTitlesService } from "../../services/dept";
import TubingAnchorHeader from '../../components/Headers/TubingAnchorHeader'
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';
import EcDragBlock from "../../components/Products/TubingAnchors/EcDragBlock";
import HcAnchorCatcher from "../../components/Products/TubingAnchors/HcAnchorCatcher";
import QtaAnchorCatcher from "../../components/Products/TubingAnchors/QtaAnchorCatcher";

const ProductController = [
  {
    title: "EC-1 DRAG BLOCK TUBING ANCHOR/CATCHER",
    component: <EcDragBlock title="EC-1 DRAG BLOCK TUBING ANCHOR/CATCHER" />,
  },
  {
    title: "HC ANCHOR CATCHER",
    component: <HcAnchorCatcher title="HC ANCHOR CATCHER" />,
  },
  {
    title: "QTA ANCHOR/CATCHER",
    component: <QtaAnchorCatcher title="QTA ANCHOR/CATCHER" />,
  },
];

function TubingAnchor() {
  const [tubingAnchor, setTubingAnchor] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("EC-1 DRAG BLOCK TUBING ANCHOR/CATCHER");

  useEffect(() => {
    getTubingAnchorTitlesService().then((result) => {
        setTubingAnchor(result);
    });
  }, []);

  return (
    <div>
    <Helmet>‍
        <title>Tubing Anchors | Maverick Downhole Technologies | Edmonton, AB</title>‍
        <meta name="description" content="Find all the best quality products your pet may need" />        
        <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Pets - Products" />        
      </Helmet>
      <TubingAnchorHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {tubingAnchor.map((tubingAnchor) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={tubingAnchor.title}
                selected={tubingAnchor.title === currentProduct}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => product.title === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default TubingAnchor;
