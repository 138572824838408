import React from "react";
import ContactForm from '../../components/ContactForm/ContactForm';
import AccessoriesBtn from "../../components/ColumnBtns/AccessoriesBtn";
import AccessoriesHeader from "../../components/Headers/AccessoriesHeader";
import { useEffect, useState } from "react";
import { getAccessoryTitlesService } from "../../services/dept";
import { Helmet } from 'react-helmet';


import ElOnOffTool from "../../components/Products/Accessories/ElOnOffTool";
import ErRotationalShearSafetyJoint from '../../components/Products/Accessories/ErRotationalShearSafetyJoint'
import EqTorqueShearSafetyJoint from '../../components/Products/Accessories/EqTorqueShearSafetyJoint'
import EdvAnnularDumpValve from '../../components/Products/Accessories/EdvAnnularDumpValve'
import BlastJoints from '../../components/Products/Accessories/BlastJoints'
import EsbSingleBurstDisk from '../../components/Products/Accessories/EsbSingleBurstDisk'
import EsbDualBurstDisk from '../../components/Products/Accessories/EsbDualBurstDisk'
import ExjExpansion from '../../components/Products/Accessories/ExjExpansion'
import WirelineAdapterKit from '../../components/Products/Accessories/WirelineAdapterKit'

const ProductController = [
  { title: "EL ON/OFF TOOL", component: <ElOnOffTool title="EL ON/OFF TOOL" /> },
  { title: "ER ROTATIONAL SHEAR SAFETY JOINT", component: <ErRotationalShearSafetyJoint title="ER ROTATIONAL SHEAR SAFETY JOINT"/> },
  { title: "EQ TORQUE-THRU SHEAR SAFETY JOINT", component: <EqTorqueShearSafetyJoint title="EQ TORQUE-THRU SHEAR SAFETY JOINT"/> },
  { title: "EDV ANNULAR DUMP VALVE", component: <EdvAnnularDumpValve title="EDV ANNULAR DUMP VALVE"/> },
  { title: "BLAST JOINTS", component: <BlastJoints title="BLAST JOINTS"/> },
  { title: "ESB SINGLE BURST DISK", component: <EsbSingleBurstDisk title="ESB SINGLE BURST DISK"/> },
  { title: "ESB DUAL BURST DISK", component: <EsbDualBurstDisk title="ESB DUAL BURST DISK"/> },
  { title: "EXJ EXPANSION", component: <ExjExpansion title="EXJ EXPANSION"/> },
  { title: "WIRELINE ADAPTER KIT", component: <WirelineAdapterKit title="WIRELINE ADAPTER KIT"/> },
]

function Accessories() {
  const [accessories, setAccessories] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("EL ON/OFF TOOL");

  useEffect(() => {
    getAccessoryTitlesService().then((result) => {
      setAccessories(result)
    })
    // document.title =
    //   "Accessories | Maverick Downhole Technologies | Edmonton, AB";
  }, []);

  return (
    <div>
       <Helmet>‍
        <title>Accessories | Maverick Downhole Technologies | Edmonton, AB</title>‍
        <meta name="description" content="Find all the best quality products your pet may need" />        
        <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Pets - Products" />        
      </Helmet>

      <AccessoriesHeader />
      <div className="product-section">
        <div className="product-btn-container">
            {
              accessories.map((accessory) => {
                return (
                  <AccessoriesBtn onClick={(title) => setCurrentProduct(title)} title={ accessory.title } selected={accessory.title === currentProduct}/>
                )
              })
            }
        </div>
        <div>
          {
            (!currentProduct) ? null : (
              ProductController.find((product) => product.title === currentProduct)?.component
            )
          }
          
          {/* <EqTorqueShearSafetyJoint title="EQ TORQUE-THRU SHEAR SAFETY JOINT"/>
          <EdvAnnularDumpValve title="EDV ANNULAR DUMP VALVE"/>
          <BlastJoints title="BLAST JOINTS"/>
          <EsbSingleBurstDisk title="ESB SINGLE BURST DISK"/>
          <EsbDualBurstDisk title="ESB DUAL BURST DISK"/>
          <ExjExpansion title="EXJ EXPANSION"/>
          <WirelineAdapterKit title="WIRELINE ADAPTER KIT"/>  */}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default Accessories;
