import axios from 'axios';

import config from '../config';

export async function getAllProductService() {
    return await axios
        .get(`${config.base_url}/dept/getAllProducts`)
        .then((result) => result.data)
        .catch((error) => console.log(`getAllProductService - ${error}`));
}


export async function getAccessoryTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getAccessoryTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getAccessoryTitles - ${error}`));
}


export async function getPlugRetainersTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getPlugRetainersTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getPlugRetainersTitles - ${error}`));
}

export async function getFlowControlTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getFlowControlTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getFlowControlTitles - ${error}`));
}

export async function getTubingAnchorTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getTubingAnchorTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getTubingAnchorTitles - ${error}`));
}

export async function getMechanicalPackerTitlesService() {
    return await axios
        .get(`${config.base_url}/dept/getMechanicalPackerTitles`)
        .then((result) => result.data)
        .catch((error) => console.log(`getMechanicalPackerTitles - ${error}`));
}
