import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getMechanicalPackerTitlesService } from "../../services/dept";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import MechanicalPackerHeader from '../../components/Headers/MechanicalPackerHeader'
import { Helmet } from 'react-helmet';
import TandemTensionPacker from "../../components/Products/MechanicalPackers/TandemTensionPacker";
import AsiXPacker from "../../components/Products/MechanicalPackers/AsiXPacker";
import WirelineAdapterKitVsi from "../../components/Products/MechanicalPackers/WirelineAdapterKitVsi";
import JettsetPacker from '../../components/Products/MechanicalPackers/JettsetPacker'
import ASIIPacker from '../../components/Products/MechanicalPackers/ASIIPacker'
import ASIIIPacker from '../../components/Products/MechanicalPackers/ASIIIPacker'
import AS5Packer from '../../components/Products/MechanicalPackers/AS5Packer'
import SnapsetIPacker from '../../components/Products/MechanicalPackers/SnapsetIPacker'
import SnapsetIIPacker from '../../components/Products/MechanicalPackers/SnapsetIIPacker'
import CtThermalPacker from '../../components/Products/MechanicalPackers/CtThermalPacker'
import IeThermalPacker from '../../components/Products/MechanicalPackers/IeThermalPacker'
import TmAnchor from '../../components/Products/MechanicalPackers/TmAnchor'
import AsiXAnchor from '../../components/Products/MechanicalPackers/AsiXAnchor'
import CasingPacker from '../../components/Products/MechanicalPackers/CasingPacker'
import GvCupPacker from '../../components/Products/MechanicalPackers/GvCupPacker'
import GasVentPumpingPacker from '../../components/Products/MechanicalPackers/GasVentPumpingPacker'

const ProductController = [
  {
    title: "TYPE DL-IB TANDEM TENSION PACKER",
    component: <TandemTensionPacker title="TYPE DL-IB TANDEM TENSION PACKER" />,
  },
  {
    title: "ASI-X PACKER",
    component: <AsiXPacker title="ASI-X PACKER" />,
  },
  {
    title: "WIRELINE ADAPTER KIT - FOR VSI-X PACKER",
    component: <WirelineAdapterKitVsi title="WIRELINE ADAPTER KIT - FOR VSI-X PACKER" />,
  },
  {
    title: "JETTSET PACKER",
    component: <JettsetPacker title="JETTSET PACKER" />,
  },
  {
    title: "AS-III PACKER",
    component: <ASIIIPacker title="AS-III PACKER" />,
  },
  {
    title: "AS-II PACKER",
    component: <ASIIPacker title="AS-II PACKER" />,
  },
  {
    title: "AS-5 PACKER",
    component: <AS5Packer title="AS-5 PACKER" />,
  },
  {
    title: "SNAPSET I PACKER",
    component: <SnapsetIPacker title="SNAPSET I PACKER" />,
  },
  {
    title: "SNAPSET II PACKER",
    component: <SnapsetIIPacker title="SNAPSET II PACKER" />,
  },
  {
    title: "CT THERMAL PACKER",
    component: <CtThermalPacker title="CT THERMAL PACKER" />,
  },
  {
    title: "IE THERMAL PACKER",
    component: <IeThermalPacker title="IE THERMAL PACKER" />,
  },
  {
    title: "TM ANCHOR/CATCHER",
    component: <TmAnchor title="TM ANCHOR/CATCHER" />,
  },
  {
    title: "ASI-X ANCHOR",
    component: <AsiXAnchor title="ASI-X ANCHOR" />,
  },
  {
    title: "CASING PACKER",
    component: <CasingPacker title="CASING PACKER" />,
  },
  {
    title: "GV CUP PACKER",
    component: <GvCupPacker title="GV CUP PACKER" />,
  },
  {
    title: "GAS VENT PUMPING PACKER",
    component: <GasVentPumpingPacker title="GAS VENT PUMPING PACKER" />,
  },
];

function MechanicalPacker() {
  const [mechanicalPacker, setMechanicalPacker] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("TYPE DL-IB TANDEM TENSION PACKER");

  useEffect(() => {
    getMechanicalPackerTitlesService().then((result) => {
        setMechanicalPacker(result);
    });
  }, []);

  return (
    <div>
    <Helmet>‍
        <title>Mechanical Packers | Maverick Downhole Technologies | Edmonton, AB</title>‍
        <meta name="description" content="Find all the best quality products your pet may need" />        
        <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Pets - Products" />        
      </Helmet>
      <MechanicalPackerHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {mechanicalPacker.map((mechanicalPacker) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={mechanicalPacker.title}
                selected={mechanicalPacker.title === currentProduct}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => product.title === currentProduct
              )?.component}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default MechanicalPacker;
