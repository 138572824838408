import React from "react";

function MechanicalPackerHeader() {
  return (
    <div className="product-header">
      <div className="product-header-inner">
        <div>
          <h4 className="smaller-head white" style={{ color: "white" }}>
            Mechanical Packers
          </h4>
        </div>
        <div>
          <p className="white">Home / Products / Tubing Anchors</p>
        </div>
      </div>
    </div>
  );
}

export default MechanicalPackerHeader;
