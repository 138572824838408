import React, { useEffect, useState } from "react";
import { getAllProductService } from "../../../services/dept";
import WlakImg from '../../../assets/images/product-images/mechanical-packers/wlak.jpg'
import WirelineAdapterKitVsiTable from "./Tables/WirelineAdapterKitVsiTable";

function WirelineAdapterKitVsi(props) {
  const { title } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
    });
  }, []);

  // console.log(currentProduct);
  return (
    <div className="product-info">
      <div>
        <h3 className="main-subhead">
          {currentProduct !== {} ? currentProduct.title : null}
        </h3>
        <h4 className="smaller-head">
          {currentProduct !== {} ? currentProduct.subhead : null}
        </h4>
        <div className="spacer"></div>
        <p className="body-text">
          {currentProduct !== {} ? currentProduct.description : null}
        </p>
        <WirelineAdapterKitVsiTable />
      </div>
      <div>
        <img
          src={WlakImg}
          title={currentProduct.title}
          alt={currentProduct.title}
          className="product-img"
        />
      </div>
    </div>
  );
}

export default WirelineAdapterKitVsi;
