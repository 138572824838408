import "./navbar.css";
import axios from 'axios'
import { useState, useEffect } from "react";
import PillarLogo from "../../assets/logos/Pillar_Logo.svg";
import { Routes, Route, Link } from "react-router-dom";
import Clock from "../../assets/icons/clock.png";
import Phone from "../../assets/icons/phone.png";
import ProductsDropdown from "./ProductsDropdown";
import GlobalSearch from "./GlobalSearch";
import config from "../../config";
import ProductData from '../../Data.json'




function NavBar() {
  // const [ProductData, setProductData] = useState({})


// useEffect(async () => {
//   await axios.get(`${config.base_url}/fetchData`).then((res) => {
//     setProductData(res)
//   })
// }, [])
  return (
    <header>
      <div className="nav-top">
        <div>
          <Link to="/">
            <img
              className="logo"
              src={PillarLogo}
              alt="Logo"
              title="Logo"
            />
          </Link>
        </div>
        <div>
          {/* <div className="nav-contact">
            <img src={Clock} alt="clock"/>
            <p>
              Monday - Friday <br />
              <strong>8am - 5pm</strong>
            </p>
          </div> */}
          <div className="nav-contact">
            <img src={Phone}  alt="phone"/>
            <p>
              <a href="tel:4036067586">
              403-606-7586
              </a>
                <br />
                <a href="mailto:info@pillarhydraulics.com?subject=Contact">
                <strong>
                info@pillarhydraulics.com
                </strong>
                </a>
            </p>
          </div>
        </div>
        <div>
        <a href="#contact">
        <button className="main-btn nav-btn">Contact Us</button>
          </a>
          <i
            class="fa-solid fa-bars"
            id="hamburger"
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            role="button"
            aria-controls="offcanvasExample"
          ></i>
        </div>
      </div>
      {/* <div className="nav-bottom">
        <div className="nav-bottom-inner">
          <nav>
            <div className="nav-links-container">
              <Link to="/about">
                <p className="nav-link">About</p>
              </Link>
              <p
                className="nav-link"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Products
              </p>
              <div class="dropdown-menu">
                <ProductsDropdown />
              </div>
              <p className="nav-link">Certifications</p>
              <p className="nav-link">Manufacturing</p>
              <Link to="/engineering">
                <p className="nav-link">Engineering</p>
              </Link>
            </div>
            <div>
             <GlobalSearch data={ProductData}/>
            </div>
          </nav>
        </div>
      </div> */}
    </header>
  );
}

export default NavBar;
