import React from "react";
import { getAllProductService } from "../../services/dept";
import { useEffect, useState } from "react";

function AccessoriesBtn(props) {
  const { title, onClick, selected } = props;
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({});

  useEffect(() => {
    getAllProductService().then((loadedProducts) => {
      setProducts(loadedProducts);
      // console.log(loadedProducts.find((product) => product.title === title));
      setCurrentProduct(
        loadedProducts.find((product) => product.title === title)
      );
      // console.log(title)
    });
  }, []);
  return (
    <div>
      {selected === true ? (
        <button className="column-btn active" onClick={() => onClick(title)}>
          {currentProduct !== {} ? currentProduct?.title : null}
        </button>
      ) : (
        <button className="column-btn" onClick={() => onClick(title)}>
          {currentProduct !== {} ? currentProduct?.title : null}
        </button>
      )}
    </div>
  );
}

export default AccessoriesBtn;
