import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useEffect, useState } from "react";
import { getAllProductService } from "../../services/dept";
import { getFlowControlTitlesService } from "../../services/dept";
import FlowControlHeader from "../../components/Headers/FlowControlHeader";
import GeneralBtn from "../../components/ColumnBtns/GeneralBtn";
import { Helmet } from 'react-helmet';

import ExExnLandingNipples from "../../components/Products/FlowControl/ExExnLandingNipples";
import EorEornLandingNipples from "../../components/Products/FlowControl/EorEornLandingNipples";
import EfErLandingNipples from "../../components/Products/FlowControl/EfErLandingNipples";
import ExaExoEnpSlidingSleeves from "../../components/Products/FlowControl/ExaExoEnpSlidingSleeves";
import ElSlidingSleeve from "../../components/Products/FlowControl/ElSlidingSleeve";

const ProductController = [
  {
    title: "EX/EXN LANDING NIPPLES",
    component: <ExExnLandingNipples title="EX/EXN LANDING NIPPLES" />,
  },
  {
    title: "EOR/EORN LANDING NIPPLES",
    component: <EorEornLandingNipples title="EOR/EORN LANDING NIPPLES" />,
  },
  {
    title: "EF/ER LANDING NIPPLES",
    component: <EfErLandingNipples title="EF/ER LANDING NIPPLES" />,
  },
  {
    title: "EXA/EXO/ENP SLIDING SLEEVES",
    component: <ExaExoEnpSlidingSleeves title="EXA/EXO/ENP SLIDING SLEEVES" />,
  },
  {
    title: "EL SLIDING SLEEVE",
    component: <ElSlidingSleeve title="EL SLIDING SLEEVE" />,
  },
];

function FlowControl() {
  const [flowControl, setFlowControl] = useState([]);
  const [currentProduct, setCurrentProduct] = useState("EX/EXN LANDING NIPPLES");

  useEffect(() => {
    getFlowControlTitlesService().then((result) => {
      setFlowControl(result);
    });
  }, []);

  return (
    <div>
    <Helmet>‍
        <title>Flow Control | Maverick Downhole Technologies | Edmonton, AB</title>‍
        <meta name="description" content="Find all the best quality products your pet may need" />        
        <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Pets - Products" />        
      </Helmet>

      <FlowControlHeader />
      <div className="product-section">
        <div className="product-btn-container">
          {flowControl.map((flowControl) => {
            return (
              <GeneralBtn
                onClick={(title) => setCurrentProduct(title)}
                title={flowControl.title}
                selected={flowControl.title === currentProduct}
              />
            );
          })}
        </div>
        <div>
          {!currentProduct
            ? null
            : ProductController.find(
                (product) => product.title === currentProduct
              )?.component}


          {/* <ExExnLandingNipples title="EX/EXN LANDING NIPPLES"/>
          <EorEornLandingNipples title="EOR/EORN LANDING NIPPLES" />
          <EfErLandingNipples title="EF/ER LANDING NIPPLES" />
          <ExaExoEnpSlidingSleeves title="EXA/EXO/ENP SLIDING SLEEVES" />
          <ElSlidingSleeve title="EL SLIDING SLEEVE" /> */}
        </div>
      </div>
      <ContactForm />
    </div>
  );
}

export default FlowControl;
