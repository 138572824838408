import logo from './logo.svg';
import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import styled, { css } from 'styled-components'
import ProductData from './Data.json'

import Home from '../src/pages/Home';
import About from '../src/pages/About';
import Engineering from './pages/Engineering';
// _______________
import Accessories from './pages/Products/Accessories';
import MechanicalPackers from '../src/pages/Products/MechanicalPackers'
import FlowControl from '../src/pages/Products/FlowControl'
import PlugRetainers from '../src/pages/Products/PlugRetainers'
import TubingAnchors from '../src/pages/Products/TubingAnchors'

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="engineering" element={<Engineering />} />
        {/* PRODUCT PAGES */}
        <Route path="products/accessories" element={<Accessories />} />
        <Route path="products/mechanical-packers" element={<MechanicalPackers />} />
        <Route path="products/flow-control" element={<FlowControl />} />
        <Route path="products/plug-retainers" element={<PlugRetainers />} />
        <Route path="products/tubing-anchors" element={<TubingAnchors />} />
      </Routes>
  );
}

export default App;
