import React from "react";
import MaverickLogoWhite from "../../assets/logos/Pillar_Logo.svg";
import { Routes, Route, Link } from "react-router-dom";

function MobileMenu() {
  return (
    <div>
      <div
        class="offcanvas offcanvas-start"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
          <div className="mobile-menu">
            <img src={MaverickLogoWhite} alt="Logo" className="mobile-logo"/>

            <div className="mobile-links">
                <Link to="about">
                <p className="mobile-link">
                    About
                </p>
                </Link>
                <p className="mobile-link"type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    Our Products &nbsp; <i class="fa-solid fa-caret-down" style={{color: '#0099cd'}}></i>
                </p>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <Link to="/products/mechanical-packers">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right" aria-controls="offcanvasExample" data-bs-toggle="offcanvas"></i>&nbsp;Mechanical Packers</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Hydraulic Packers</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Permanent Packers</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Retrievable Packers</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Plug/Cement Retainers</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Seal Bore Packers</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Tubing Anchors</p>
                    </div>
                  </Link>
                  <Link to="/products/flow-control">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Flow Control</p>
                    </div>
                  </Link>
                  <Link to="">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Service Tools</p>
                    </div>
                  </Link>
                  <Link to="/products/accessories">
                    <div>
                      <p className="mobile-dropdown"><i class="fa-solid fa-arrow-right"></i>&nbsp;Accessories</p>
                    </div>
                  </Link>
                </div>
                <p className="mobile-link">
                    Certifications
                </p>
                <p className="mobile-link">
                    Manufacturing
                </p>
                <p className="mobile-link">
                    Engineering
                </p>
                <input
                className="search"
                type="text"
                placeholder="search..."
                id="globalSearch"
              ></input>
            </div>
          </div>
      </div>
    </div>
  );
}

export default MobileMenu;
