import React, { Component } from "react";
// import PreloaderLogo from "../../../src/assets/logos/MaverickLogoAnimated_TransparentBlack.gif";
import $ from "jquery";

class Preloader extends Component {
  componentDidCatch() {}
  render() {
    return (
      <div>
        <div className="preloader-container" id="preloader">
          {/* <img src={PreloaderLogo} alt="Maverick Logo" /> */}
        </div>
      </div>
    );
  }
}

export default Preloader;
