import Tool from "../../../assets/icons/tool.png";
import inventory from "../../../assets/icons/inventory.png";
import supply from "../../../assets/icons/supply.png";

function ReliableToolSupply() {
  return (
    <div className="reliable-tool-supply">
      <div className="inner-container">
        <div>
          <img src={Tool} alt="icon" className="tool-icon"/>
          <p className="reliable-tool-heading">Reliable Tool Supply</p>
        </div>
        <div>
          <img src={supply} alt="icon" className="tool-icon"/>
          <p className="reliable-tool-heading">Supply Chain Management</p>
        </div>
        <div>
          <img src={inventory} alt="icon" className="tool-icon"/>
          <p className="reliable-tool-heading">Inventory Stocking Agreements</p>
        </div>
      </div>
    </div>
  );
}

export default ReliableToolSupply;
