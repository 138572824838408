import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing Size</th>
                <th colSpan={2}>Casing Weight</th>
                <th colSpan={2}>Plug OD</th>
                <th colSpan={2}>Setting Min</th>
                <th colSpan={2}>Setting Max</th>
                <th colSpan={2}>Pressure Rating</th>
                <th colSpan={2}>Setting Force</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>PSI</td>
                <td>KPA</td>
                <td>lbs</td>
                <td>daN</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>9.5-15.1</td>
                <td>14.1-22.5</td>
                <td>3.562</td>
                <td>90.47</td>
                <td>3.826</td>
                <td>97.18</td>
                <td>1.090</td>
                <td>103.89</td>
                <td>5,000</td>
                <td>35,000</td>
                <td>33,000</td>
                <td>14.678</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>13.0-23.0</td>
                <td>19.3-34.2</td>
                <td>4.312</td>
                <td>109.52</td>
                <td>4.580</td>
                <td>116.33</td>
                <td>5.044</td>
                <td>128.12</td>
                <td>5,000</td>
                <td>35,000</td>
                <td>33,000</td>
                <td>14.678</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>17.0-35.0</td>
                <td>25.3-52.1</td>
                <td>5.687</td>
                <td>144.45</td>
                <td>6.000</td>
                <td>152.40</td>
                <td>6.538</td>
                <td>166.07</td>
                <td>5,000</td>
                <td>35,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>10 <sup>3/4</sup></td>
                <td>269.9</td>
                <td>32.7-60.7</td>
                <td>48.7-90.3</td>
                <td>9.437</td>
                <td>239.70</td>
                <td>9.660</td>
                <td>245.36</td>
                <td>10.192</td>
                <td>258.88</td>
                <td>5,000</td>
                <td>35,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>11 <sup>3/4</sup></td>
                <td>298.5</td>
                <td>38.0-60.0</td>
                <td>56.-89.3</td>
                <td>10.437</td>
                <td>265.10</td>
                <td>10.772</td>
                <td>273.61</td>
                <td>11.150</td>
                <td>283.21</td>
                <td>4,000</td>
                <td>28,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>11 <sup>3/4</sup></td>
                <td>298.5</td>
                <td>60.0-83.0</td>
                <td>89.3-123.5</td>
                <td>9.937</td>
                <td>252.40</td>
                <td>10.192</td>
                <td>258.88</td>
                <td>10.772</td>
                <td>273.61</td>
                <td>4,000</td>
                <td>28,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>13 <sup>3/8</sup></td>
                <td>339.7</td>
                <td>48.0-84.5</td>
                <td>71.4-125.7</td>
                <td>11.880</td>
                <td>301.75</td>
                <td>12.125</td>
                <td>307.98</td>
                <td>12.715</td>
                <td>322.96</td>
                <td>3,000</td>
                <td>21,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>16</td>
                <td>406.4</td>
                <td>65.0-118.0</td>
                <td>96.7-175.5</td>
                <td>14.125</td>
                <td>358.78</td>
                <td>14.125</td>
                <td>358.78</td>
                <td>15.250</td>
                <td>387.35</td>
                <td>2,000</td>
                <td>14,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>20</td>
                <td>508.0</td>
                <td>94.0-133.0</td>
                <td>139.8-197-8</td>
                <td>18.730</td>
                <td>475.74</td>
                <td>18.730</td>
                <td>475.74</td>
                <td>19.124</td>
                <td>485.75</td>
                <td>2,000</td>
                <td>14,000</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable