import axios from "axios";
import config from "../../config";
import { notification } from "antd";
import { Modal } from "antd";
import "antd/dist/antd.css";
import Logo from "../../assets/logos/Pillar_Logomark.svg";

async function sendEmail(e) {
  console.log("submit1", document.getElementById("name").value);
  e.preventDefault();
  await axios
    //Common backend for form su
    .post(`https://site-api.maverickdownhole.com/form/sendPillarEmail`, {
      name: document.getElementById("name").value,
      phone: document.getElementById("phone").value,
      email: document.getElementById("email").value,
      message: document.getElementById("message").value,
    })
    .then((res) => {
      //console.log("RES", res);
      document.getElementById("name").value = "";
      document.getElementById("phone").value = "";
      document.getElementById("email").value = "";
      document.getElementById("message").value = "";

      notification.success({
        message: "Message Sent!",
        description: "Thank you. Your message has been sent.",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    })
    .catch((err) => {
      notification.error({
        message: "Message Failed",
        description:
          "Sorry, your message did not go through. Please try again.",
        onClick: () => {
          console.log("Notification Clicked!");
        },
      });
    });
}

function ContactForm() {
  return (
    <div>
      <div className="contact-form-container" id="contact">
        <div className="form-grid">
          <div>
            <h3 className="main-subhead mb20 white">
              Get in <span className="bold">Touch</span>
            </h3>
            <form id="form" onSubmit={sendEmail}>
              <div className="form-top">
                <input type="text" id="name" placeholder="Full Name" required />
                <input
                  type="text"
                  id="phone"
                  placeholder="Phone Number"
                  className="no-margin"
                  required
                />
              </div>
              <input
                type="text"
                id="email"
                placeholder="Email Address"
                required
              />
              <textarea id="message" placeholder="Message"></textarea>
              <button className="main-btn" type="submit">
                Submit
              </button>
            </form>
          </div>
          <div>
            <div className="contact-form-info">
                <img
                src={Logo}
                className="contact-logo"
                alt="Logo"
                title="Logo"
                loading="lazy"
              />
              <a href="tel:4036067586">
                <p className="mb20">403-606-7586</p>
              </a>
              <a href="mailto:info@pillarhydraulic.com">
                <p className="mb20">info@pillarhydraulics.com</p>
              </a>
              {/* <a
                href="https://www.google.com/maps/place/2618+Hopewell+Pl+NE+%23210,+Calgary,+AB+T1Y+7J7/@51.0918496,-113.9996857,16z/data=!3m1!4b1!4m5!3m4!1s0x537164f1deae4c51:0x62d43fc7a781e26e!8m2!3d51.0918496!4d-113.9996857?entry=ttu"
                target="_blank"
              >
                <p className="mb20">
                  2618 Hopewell Pl NE, Unit 210 <br />
                  Calgary, AB
                </p>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactForm;
