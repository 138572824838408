import HomeHero from '../components/Home/HomeHero/HomeHero'
import ContactForm from '../components/ContactForm/ContactForm'
import AboutUs from '../components/Home/AboutUs/AboutUs'
import ReliableToolSupply from '../components/Home/ReliableToolSupply/ReliableToolSupply';
import ProductsHome from '../components/Home/ProductsHome/ProductsHome';
import Preloader from '../components/Preloader/preloader'
import {useEffect} from 'react';

function Home() {
    useEffect(() => {
        document.title = "Pillar Hydraulic Solutions"
    })
    return (
        <>
            <Preloader />
            <HomeHero />
            <AboutUs />
            <ReliableToolSupply />
            <ContactForm />
        </>
    );
  }

  export default Home;
