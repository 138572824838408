import React from "react";
import styled from "styled-components";
import Helmet from "helmet";
import { Link } from "react-router-dom";
import ScrollReveal from 'scrollreveal'

import Mechanical from '../../../assets/images/home-images/mechanical.png'
import Hydraulic from '../../../assets/images/home-images/hydraulic.png'
import Accessories from '../../../assets/images/home-images/accessories.png'
import ServiceTools from '../../../assets/images/home-images/service-tools.png'
import Permanent from '../../../assets/images/home-images/permanent.png'

const ProductCard = styled.div`
  position: relative;
  box-shadow: 0px 0px 5px rgba(134, 137, 136, 0.4);
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
`;

function ProductsHome() {

  // window.sr = ScrollReveal();
  
  // sr.reveal('.product-grid div', {
  //   duration   : 600,
  //   distance   : '20px',
  //   easing     : 'ease-out',
  //   origin     : 'bottom',
  //   reset      : true,
  //   scale      : 1,
  //   viewFactor : 0,
  //   afterReveal  : revealChildren,
  // }, 150);
  
  //   var revealChildren = sr.reveal('.product-card-title .body-text', {
  //   duration   : 500,
  //   scale      : 1,
  //   distance   : '20px',
  //   origin     : 'bottom',
  //   reset      : true,
  //   easing     : 'ease-out',
  //   viewFactor : 1,
  // }, 75);

  const products = [
    {
      id: 1,
      number: "1",
      title: "Mechanical Set Packers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
      img: Mechanical,
      link: "/products/mechanical-packers",
    },
    {
      id: 2,
      number: "2",
      title: "Hydraulic Set Packers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
      img: Hydraulic,
      link: "/products/hydraulic-packers",
    },
    {
      id: 3,
      number: "3",
      title: "Permanent Packers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
      img: Permanent,
      link: "/products/permanent-packers",
    },
    {
      id: 4,
      number: "4",
      title: "Retrievable Packers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
      img:  Mechanical,
      link: "/products/retrievable-packers",
    },
    {
      id: 5,
      number: "5",
      title: "Seal Bore Packers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
      img:  Mechanical,
      link: "/products/seal-bore-packers",
    },
    {
        id: 6,
        number: "6",
        title: "Tubing Anchors",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
        img:  Mechanical,
        link: "/products/tubing-anchors",
      },
      {
        id: 7,
        number: "7",
        title: "Flow Control",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
        img:  Mechanical,
        link: "/products/flow-control",
      },
      {
        id: 8,
        number: "8",
        title: "Plug/Cement Retainers",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
        img:  Mechanical,
        link: "/products/plug-retainers",
      },
      {
        id: 9,
        number: "9",
        title: "Service Tools",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
        img: ServiceTools,
        link: "/products/service-tools",
      },
      {
        id: 10,
        number: "10",
        title: "Accessories",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores magni facere rerum. Placeat provident minus porro",
        img: Accessories,
        link: "/products/accessories",
      },
  ];
  return (
    <div className="products-home">
      <div className="inner-container-products-home">
        <h3 className="main-subhead">
          Our<span className="bold"> Products</span>
        </h3>
        <p className="body-text">
          More Than 130 Different Products Available In All Sizes
        </p>
        <Helmet>
          <tite>Test</tite>
        </Helmet>
        {/* <div className="product-grid"> */}
          <div className="product-grid">
            {products.map(({ number, title, img, description, link }) => {
              return (
                <>
                <div>
                  <div className="product-card-top">
                    <div className="product-img-container">
                        <img src={`${img}`} alt={title} title={title} className="product-img-home"/>
                    </div>
                    <p>{number}</p>
                    <h4 className="product-card-title">{title}</h4>
                  </div>
                  <div className="product-card-bottom">
                    <p className="body-text">{description}</p>
                    <Link to={`${link}`}>
                      <button className="main-btn">View Products</button>
                    </Link>
                  </div>
                  </div>
                </>
              );
            })}
          </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default ProductsHome;
