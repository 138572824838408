import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr style={{backgroundColor: '#BCBEC0', color: 'black'}}>
                <th colSpan={2} style={{backgroundColor: 'white'}}></th>
                <th colSpan={4}>Type TX</th>
                <th colSpan={6}>Type TXN</th>
            </tr>
            <tr>
                <th colSpan={2}>Tubing OD</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>Min OD</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>No-GO-ID</th>
                <th colSpan={2}>Min OD</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>1.660</td>
                <td>42.15</td>
               <td>1.250</td>
               <td>31.75</td>
               <td>1.890</td>
               <td>48.01</td>
               <td>1.250</td>
               <td>31.75</td>
               <td>1.135</td>
               <td>28.83</td>
               <td>1.890</td>
               <td>48.01</td>
            </tr>
            <tr>
                <td>1.900</td>
                <td>48.26</td>
               <td>1.500</td>
               <td>38.10</td>
               <td>2.130</td>
               <td>54.10</td>
               <td>1.500</td>
               <td>38.10</td>
               <td>1.448</td>
               <td>36.78</td>
               <td>2.130</td>
               <td>54.10</td>
            </tr>
            <tr>
                <td>2.063</td>
                <td>53.37</td>
               <td>1.625</td>
               <td>41.28</td>
               <td>2.310</td>
               <td>59.44</td>
               <td>1.625</td>
               <td>41.28</td>
               <td>1.536</td>
               <td>39.01</td>
               <td>2.340</td>
               <td>59.44</td>
            </tr>
            <tr>
                <td>2.375</td>
                <td>60.33</td>
               <td>1.875</td>
               <td>47.60</td>
               <td>2.710</td>
               <td>68.83</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>1.791</td>
               <td>45.49</td>
               <td>2.710</td>
               <td>68.83</td>
            </tr>
            <tr>
                <td>2.875</td>
                <td>73.03</td>
               <td>2.312</td>
               <td>58.72</td>
               <td>3.230</td>
               <td>82.04</td>
               <td>2.312</td>
               <td>58.72</td>
               <td>2.205</td>
               <td>56.01</td>
               <td>3.230</td>
               <td>82.04</td>
            </tr>
            <tr>
                <td>3.500</td>
                <td>88.90</td>
               <td>2.750</td>
               <td>69.85</td>
               <td>4.250</td>
               <td>107.95</td>
               <td>2.750</td>
               <td>69.85</td>
               <td>2.635</td>
               <td>66.93</td>
               <td>4.250</td>
               <td>107.95</td>
            </tr>
            <tr>
                <td>3.500</td>
                <td>88.90</td>
               <td>2.812</td>
               <td>71.42</td>
               <td>4.250</td>
               <td>107.95</td>
               <td>2.812</td>
               <td>71.42</td>
               <td>2.666</td>
               <td>67.72</td>
               <td>4.250</td>
               <td>107.95</td>
            </tr>
            <tr>
                <td>4.500</td>
                <td>114.30</td>
               <td>3.812</td>
               <td>96.82</td>
               <td>4.940</td>
               <td>125.48</td>
               <td>3.812</td>
               <td>96.82</td>
               <td>3.725</td>
               <td>94.62</td>
               <td>4.940</td>
               <td>125.48</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable