import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr>
                <th colSpan={2}>Casing Size</th>
                <th colSpan={2}>Casing Weight</th>
                <th colSpan={2}>Plug OD</th>
                <th colSpan={2}>Setting Min</th>
                <th colSpan={2}>Setting Max</th>
                <th colSpan={2}>Setting Force</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
                <td>lbs/ft</td>
                <td>kg/m</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>in.</td>
                <td>mm</td>
                <td>lbs</td>
                <td>daN</td>
            </tr>
            <tr>
                <td>2 <sup>3/8</sup></td>
                <td>60.3</td>
                <td>4.0-5.8</td>
                <td>6.0-8.6</td>
                <td>1.750</td>
                <td>44.45</td>
                <td>1.780</td>
                <td>45.21</td>
                <td>2.074</td>
                <td>52.68</td>
                <td>13,000</td>
                <td>5,782</td>
            </tr>
            <tr>
                <td>2 <sup>7/8</sup></td>
                <td>73.0</td>
                <td>6.4-6.5</td>
                <td>9.5-9.7</td>
                <td>2.220</td>
                <td>56.39</td>
                <td>2.340</td>
                <td>59.44</td>
                <td>2.525</td>
                <td>64.14</td>
                <td>13,000</td>
                <td>5,782</td>
            </tr>
            <tr>
                <td>3 <sup>1/2</sup></td>
                <td>88.9</td>
                <td>5.7-10.3</td>
                <td>8.6-14.9</td>
                <td>2.750</td>
                <td>69.85</td>
                <td>2.867</td>
                <td>72.82</td>
                <td>3.258</td>
                <td>82.75</td>
                <td>13,000</td>
                <td>5,782</td>
            </tr>
            <tr>
                <td>4</td>
                <td>101.6</td>
                <td>4.6-14.0</td>
                <td>8.3-20.8</td>
                <td>3.140</td>
                <td>79.76</td>
                <td>3.340</td>
                <td>84.84</td>
                <td>3.732</td>
                <td>94.79</td>
                <td>20,000</td>
                <td>8,896</td>
            </tr>
            <tr>
                <td>4 <sup>1/2</sup></td>
                <td>114.3</td>
                <td>9.5-15.1</td>
                <td>14.1-22.5</td>
                <td>3.562</td>
                <td>90.47</td>
                <td>3.826</td>
                <td>97.18</td>
                <td>4.090</td>
                <td>103.89</td>
                <td>33,000</td>
                <td>14,678</td>
            </tr>
            <tr>
                <td>5</td>
                <td>127.0</td>
                <td>11.5-20.8</td>
                <td>17.1-31.0</td>
                <td>3.937</td>
                <td>100.00</td>
                <td>4.154</td>
                <td>105.51</td>
                <td>4.560</td>
                <td>115.82</td>
                <td>33,000</td>
                <td>14,678</td>
            </tr>
            <tr>
                <td>5 <sup>1/2</sup></td>
                <td>139.7</td>
                <td>13.0-23.0</td>
                <td>19.3-34.2</td>
                <td>4.312</td>
                <td>109.52</td>
                <td>4.580</td>
                <td>116.33</td>
                <td>5.044</td>
                <td>128.12</td>
                <td>33,000</td>
                <td>14,678</td>
            </tr>
            <tr>
                <td>5 <sup>3/4</sup></td>
                <td>146.0</td>
                <td>14.0-25.2</td>
                <td>20.8-37.5</td>
                <td>4.699</td>
                <td>119.35</td>
                <td>4.890</td>
                <td>124.21</td>
                <td>5.290</td>
                <td>134.37</td>
                <td>33,000</td>
                <td>14,678</td>
            </tr>
            <tr>
                <td>6 <sup>5/8</sup></td>
                <td>168.3</td>
                <td>17.0-32.0</td>
                <td>25.3-48.0</td>
                <td>5.375</td>
                <td>136.53</td>
                <td>5.595</td>
                <td>142.11</td>
                <td>6.135</td>
                <td>155.83</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>7</td>
                <td>177.8</td>
                <td>17.0-35.0</td>
                <td>25.3-52.1</td>
                <td>5.687</td>
                <td>144.45</td>
                <td>6.000</td>
                <td>152.40</td>
                <td>6.538</td>
                <td>166.07</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>7 <sup>5/8</sup></td>
                <td>193.7</td>
                <td>20.0-39.0</td>
                <td>29.8-58.0</td>
                <td>6.312</td>
                <td>160.32</td>
                <td>6.625</td>
                <td>168.28</td>
                <td>7.125</td>
                <td>180.98</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>8 <sup>5/8</sup></td>
                <td>219.1</td>
                <td>20.0-39.0</td>
                <td>29.8-58.0</td>
                <td>6.312</td>
                <td>160.32</td>
                <td>6.625</td>
                <td>168.28</td>
                <td>7.125</td>
                <td>180.98</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
            <tr>
                <td>9 <sup>5/8</sup></td>
                <td>244.4</td>
                <td>29.3-58.4</td>
                <td>43.6-86.9</td>
                <td>8.125</td>
                <td>206.38</td>
                <td>8.379</td>
                <td>212.83</td>
                <td>9.063</td>
                <td>230.20</td>
                <td>50,000</td>
                <td>22,240</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable