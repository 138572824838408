import './HomeHero.css'
import { Carousel } from 'antd';

function HomeHeroNew() {
    return(
        <div className="">
            <Carousel arrows={true} autoplay={true} >
                <div className='home-hero-img home-hero-img-1'/>
                <div className='home-hero-img home-hero-img-2'/>
                <div className='home-hero-img home-hero-img-3'/>
                <div className='home-hero-img home-hero-img-4'/>
            </Carousel>
            <div className="home-hero-text-outer">
                <div className="home-hero-text">
                    <h1 className="large-headline white">
                    The most advanced <br /><span className="bold">Hydraulics</span> in Canada
                    </h1>
                    <a href="#contact">
                        <button className="hero-btn">
                            Contact Us
                        </button>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default HomeHeroNew; 