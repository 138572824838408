import HomeHero from "../components/Home/HomeHero/HomeHero";
import ContactForm from "../components/ContactForm/ContactForm";
import AboutUs from "../components/Home/AboutUs/AboutUs";
import ReliableToolSupply from "../components/Home/ReliableToolSupply/ReliableToolSupply";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import AboutHeader from "../components/About/AboutHeader";
import AboutContent from "../components/About/AboutContent";
import {useEffect} from 'react';
import OurTeam from "../components/About/OurTeam";

function Home() {
  useEffect(() => {
    document.title = "About Us | Pillar Hydraulic Solutions"
  })
  return (
    <>
      {/* <AboutHeader />
      <AboutContent />
      <OurTeam /> */}
    </>
  );
}

export default Home;
