import Footer from "../Footer/Footer.css";
import PillarLogo from "../../assets/logos/Pillar_FullLogo_White.svg";
import CanadaFlag from "../../assets/icons/CanadaFlag.png";
import MexicoFlag from "../../assets/icons/MexicoFlag.png";

function FooterMaverick() {
  return (
    <footer>
      <div className="footer-grid-container">
        <div className="footer-row">
          <img
            src={PillarLogo}
            className="footer-logo"
            alt="Logo"
            title="Logo"
            loading="lazy"
          />
          <p className="footer-text">
            Pillar Hydraulic's mission is to act as the preferred supply partner of
            hydraulic tools to the world’s most important service companies.
          </p>
        </div>
        <div className="footer-item">
          <p class="footer-heading">
            Get in <span className="bold">Touch</span>
          </p>
          <div className="contact-info">
            <div>
              {/* <img src={CanadaFlag} alt={CanadaFlag} className="flag" /> */}
              <a href="tel:4036067586">
              <p className="footer-text">
              403-606-7586</p></a>
              <p className="footer-text">info@pillarhydraulics.com</p>
              {/* <address className="footer-text">
              2618 Hopewell Pl NE, Unit 210 <br />
              Calgary, AB
              </address> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterMaverick;
