import React from 'react'
import {$,jQuery} from 'jquery';

function ElOnOffToolTable() {
    
  return (
    <div>  
        <table id="example" class="table table-striped table-bordered" cellspacing="0" width="100%">
        <thead>
            <tr style={{backgroundColor: '#BCBEC0', color: 'black'}}>
                <th colSpan={2} style={{backgroundColor: 'white'}}></th>
                <th colSpan={4}>Type TX</th>
                <th colSpan={6}>Type TXN</th>
            </tr>
            <tr>
                <th colSpan={2}>Tubing OD</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>Min OD</th>
                <th colSpan={2}>Seal Bore</th>
                <th colSpan={2}>No-GO-ID</th>
                <th colSpan={2}>Min OD</th>
            </tr>
        </thead>
 
        <tbody>
            <tr style={{backgroundColor: '#BCBEC0'}}>
                <td>in.</td>
                <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
               <td>in.</td>
               <td>mm</td>
            </tr>
            <tr>
                <td>2.375</td>
                <td>60.33</td>
               <td>1.781</td>
               <td>45.24</td>
               <td>3.063</td>
               <td>77.80</td>
               <td>1.781</td>
               <td>45.24</td>
               <td>1.640</td>
               <td>41.66</td>
               <td>3.063</td>
               <td>77.80</td>
            </tr>
            <tr>
                <td>2.375</td>
                <td>60.33</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>3.063</td>
               <td>77.80</td>
               <td>1.875</td>
               <td>47.63</td>
               <td>1.716</td>
               <td>43.59</td>
               <td>3.063</td>
               <td>77.80</td>
            </tr>
            <tr>
                <td>2.875</td>
                <td>73.03</td>
               <td>2.125</td>
               <td>53.98</td>
               <td>3.668</td>
               <td>93.17</td>
               <td>2.125</td>
               <td>53.98</td>
               <td>1.937</td>
               <td>49.20</td>
               <td>3.668</td>
               <td>93.17</td>
            </tr>
            <tr>
                <td>2.875</td>
                <td>73.03</td>
               <td>2.313</td>
               <td>58.75</td>
               <td>3.668</td>
               <td>93.17</td>
               <td>2.313</td>
               <td>58.75</td>
               <td>2.131</td>
               <td>54.13</td>
               <td>3.668</td>
               <td>93.17</td>
            </tr>
            <tr>
                <td>3.500</td>
                <td>88.90</td>
               <td>2.562</td>
               <td>65.07</td>
               <td>4.500</td>
               <td>114.30</td>
               <td>2.562</td>
               <td>65.07</td>
               <td>2.329</td>
               <td>59.16</td>
               <td>4.500</td>
               <td>114.30</td>
            </tr>
        </tbody>
        </table>
    </div>
  )
}

export default ElOnOffToolTable